const header = document.querySelector("header");

window.addEventListener("scroll", function (event) {
  if (this.pageYOffset > 50) {
    header.classList.add("nav-scrolled");
  } else {
    header.classList.remove("nav-scrolled");
  }
});

const hamburger = document.querySelector(".hamburger");
const mobileNav = document.querySelector(".navigation-block-mobile");

hamburger.addEventListener("click", (event) => {
  hamburger.classList.toggle("active");
  mobileNav.classList.toggle("show");
});
